// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { DEFAULT_PAGINATION, Pagination, Sorting } from '@app/core/types/common';
import { ApiService, CurrentUserService, ToasterService } from 'app/core/services';
import { HttpClient } from '@angular/common/http';
import {
  PostponedData,
  QuotationStatusFilter,
  Quotation,
  QuotationResponseWithError,
  QuotationStatus,
  QuotationToUpdate,
  SpecialRequest,
} from '@app/core/types/quotation';
import { catchError, debounceTime, filter, map, switchMap, skip } from 'rxjs/operators';
import { NotFoundError } from '@app/core/errors/NotFoundError';
import { QuotationHistory } from '@app/core/types/history';

const DEFAULT_SORTING: Sorting = {
  sortBy: undefined,
  sortDir: undefined,
};
const DEFAULT_SEARCH_TEXT = '';

@Injectable()
export class QuotationService {
  quotations = new BehaviorSubject<QuotationResponseWithError | undefined>(undefined);
  searchText = new BehaviorSubject<string>(DEFAULT_SEARCH_TEXT);
  caseReference = new BehaviorSubject<string | undefined>(undefined);
  showOnlySubscribed = new BehaviorSubject<boolean>(false);
  filterByStatus = new BehaviorSubject<QuotationStatusFilter>({ status: [] });
  filterByEnquiry = new BehaviorSubject<string | undefined>(undefined);
  pagination = new BehaviorSubject<Pagination>(DEFAULT_PAGINATION);
  sorting = new BehaviorSubject<Sorting>(DEFAULT_SORTING);
  loading = new BehaviorSubject<boolean>(true);
  referenceNumberOrId = new BehaviorSubject<{ id?: string; referenceNumber?: number } | undefined>(
    undefined,
  );
  attachmentsUpdated = new BehaviorSubject<boolean>(true);

  quotationListStatusChanged = new BehaviorSubject<{ id?: string; newStatus?: QuotationStatus }>({
    id: undefined,
    newStatus: undefined,
  });

  constructor(
    private _apiService: ApiService,
    private _httpClient: HttpClient,
    private _currentUserService: CurrentUserService,
    private _toasterService: ToasterService,
  ) {
    /** Search changes - reset & reload **/
    combineLatest([
      this.searchText,
      this.caseReference,
      this.filterByStatus,
      this.filterByEnquiry,
      this.sorting,
      this.showOnlySubscribed,
    ]).subscribe(() => {
      this.pagination.next({ pageIndex: 0, pageSize: this.pagination.value.pageSize });
      if (this.referenceNumberOrId.value) {
        this.referenceNumberOrId.next(undefined);
      }
    });
  }

  getQuotationById(quotationId: string): Observable<Quotation> {
    return this._apiService.getQuotation(quotationId);
  }

  reloadQuotations(showLoader = true) {
    if (showLoader) this.loading.next(true);
    this.fetchQuotations().subscribe((data) => {
      this.quotations.next(data);
      this.loading.next(false);
    });
  }

  private fetchQuotations(): Observable<QuotationResponseWithError> {
    return this._apiService
      .getQuotationsForList(
        {
          search: this.searchText.value.trim(),
          status: this.filterByStatus.value?.status,
          quotationId: this.referenceNumberOrId.value?.id,
          enquiryId: this.filterByEnquiry.value,
          referenceNumber: this.referenceNumberOrId.value?.referenceNumber,
          onlySubscribed: this.showOnlySubscribed.value,
          caseReference: this.caseReference.value,
        },
        { page: this.pagination.value.pageIndex + 1, pageSize: this.pagination.value.pageSize },
        this.sorting.value,
      )
      .pipe(
        catchError((err) => {
          this._toasterService.showToaster('apiError.generic', {
            type: 'error',
          });
          return of({ quotations: [], total: 0, allReferences: [], error: err });
        }),
      );
  }

  declineCancel(
    quotation: Quotation,
    updateData: QuotationToUpdate,
    declineReason: string,
    behalfOf?: 'airline' | 'client',
  ): Observable<{ message: string }> {
    const isWaitingForOffer = [
      QuotationStatus.WAITING_FOR_OFFER,
      QuotationStatus.POSTPONED,
    ].includes(quotation.status);

    const currentUser = this._currentUserService.getUser();
    const isAirline = !!currentUser.airline || behalfOf === 'airline';
    const isClient = !!currentUser.client || behalfOf === 'client';

    const status =
      (isWaitingForOffer && isClient) || (!isWaitingForOffer && isAirline)
        ? QuotationStatus.CANCELLED
        : QuotationStatus.DECLINED;

    if (status === QuotationStatus.DECLINED) {
      return this._apiService.quotationDecline(quotation._id, updateData, declineReason, behalfOf);
    } else {
      return this._apiService.quotationCancel(quotation._id, declineReason, behalfOf);
    }
  }

  postQuotation(
    enquiryId: string,
    airlineId: string,
    airplaneId: string | undefined,
    clientId: string | undefined,
  ): Observable<{ quotationId: string }> {
    return this._apiService.adminCreateQuotation(enquiryId, airlineId, airplaneId, clientId);
  }

  fetchQuotationHistory(quotationId: string): Observable<QuotationHistory> {
    return this._apiService.getQuotationHistory(quotationId);
  }

  setShowOnlySubscribed(showOnlySubscribedValue: boolean): void {
    this.showOnlySubscribed.next(showOnlySubscribedValue);
  }

  setOrderStatusFilter(filter: QuotationStatus[]) {
    this.filterByStatus.next({ status: filter });
  }

  setEnquiryFilter(enquiryId: string | undefined): void {
    this.filterByEnquiry.next(enquiryId);
  }

  resetPaginationPage(): void {
    this.pagination.next({
      ...this.pagination.value,
      pageIndex: DEFAULT_PAGINATION.pageIndex,
    });
  }

  isOrder(quotation): boolean {
    return (
      quotation.status === QuotationStatus.ORDER_WAITING ||
      quotation.status === QuotationStatus.ORDER_PAID ||
      quotation.status === QuotationStatus.ORDER_CANCELLED ||
      quotation.status === QuotationStatus.ORDER_DEPOSIT_PAID ||
      quotation.status === QuotationStatus.ORDER_IN_PROGRESS ||
      quotation.status === QuotationStatus.ORDER_COMPLETED
    );
  }

  resetSorting(): void {
    this.sorting.next(DEFAULT_SORTING);
  }

  resetSearchText(searchText?: string): void {
    this.searchText.next(searchText || DEFAULT_SEARCH_TEXT);
  }

  setCaseReferenceFilter(caseRef: string): void {
    this.caseReference.next(caseRef);
  }

  updateOrderCrewNotes(orderId: string, crewNotes: string): Observable<void> {
    return this._apiService.updateOrderCrewNotes(orderId, crewNotes).pipe(map(() => {}));
  }

  updateOrderGroundServiceNotes(id: string, crewNotes: string): Observable<void> {
    return this._apiService.updateOrderGroundServiceNotes(id, crewNotes).pipe(map(() => {}));
  }

  updateOrderCateringNotes(id: string, notes: string): Observable<void> {
    return this._apiService.updateOrderCateringNotes(id, notes).pipe(map(() => {}));
  }

  download(id, filename, type): Observable<any> {
    if (type === 'messages') {
      return this._apiService.downloadMessageFile(id, filename);
    } else if (type === 'attachment') {
      return this._apiService.downloadAttachment(id, filename);
    } else if (type === 'contract-terms') {
      return this._apiService.downloadContractTerms(id, filename);
    } else if (type === 'invoices') {
      return this._apiService.downloadInvoiceFile(id, filename);
    } else if (type === 'pax-list') {
      return this._apiService.downloadPaxList(id, filename);
    }
    throw 'Download not possible!';
  }

  changeVisibleListStatus(id: string, newStatus: QuotationStatus) {
    this.quotationListStatusChanged.next({ id, newStatus });
  }
  updateSpecialRequest(
    orderId: string,
    specialRequests: SpecialRequest[],
  ): Observable<SpecialRequest[]> {
    return this._apiService.updateSpecialRequest(orderId, specialRequests);
  }
}
