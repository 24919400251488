import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { ClientType, RequestPagination, Sorting } from '@app/core/types/common';
import { Attachment, AttachmentType } from '@app/core/types/attachment';
import {
  Airplane,
  AirplaneListFilter,
  AirplaneListItem,
  AirplaneToUpdate,
} from '@app/core/types/airplane';
import { LLMMessage } from '@app/core/types/llm';
import { UserToUpdate, User } from '@app/core/types/user';
import { catchError, map } from 'rxjs/operators';
import { StatsOverview } from '@app/core/types/stats';
import { arrayToQueryParams, objectToQueryParams } from '@app/shared/helpers/url-helpers';
import {
  EmergencyContact,
  OrderAction,
  PostponedData,
  PriceCondition,
  Quotation,
  QuotationResponse,
  QuotationRouteToUpdate,
  QuotationStatus,
  QuotationToUpdate,
  SpecialRequest,
} from '@app/core/types/quotation';
import { ChatMessage, RecentMessageInformation } from '@app/core/types/message';
import { Login, LoginResponse } from '@app/core/types/auth';
import { ExternalAirline, ExternalBooking } from '@app/core/types/externalBooking';
import { QuotationHistory } from '@app/core/types/history';
import {
  Airline,
  AirlineForSelect,
  AirlineListItem,
  LimitedAirline,
} from '@app/core/types/airline';
import { Client, ClientForSelect, ClientListItem, ClientUpdate } from '@app/core/types/client';
import { RoutesSearchResult } from '@app/core/types/route';
import { Aircraft } from '@app/core/types/aircraft';
import { Enquiry, EnquiryListItem, EnquiryPostData } from '@app/core/types/enquiry';
import {
  Airport,
  AirportGroup,
  AirportGroupUpdate,
  AirportWithAirplanes,
} from '@app/core/types/airport';
import { ActionLog, ActionLogFilter } from '@app/core/types/actionLog';
import { Router } from '@angular/router';
import { Commission, CommissionListItem, QuotationCommission } from '@app/core/types/commission';
import { UnsavedChangesService } from '@app/core/services/unsavedChanges.service';
import {
  NotificationForUser,
  NotificationListItem,
  NotificationProperties,
  NotificationSource,
  NotificationType,
  NotificationUpdate,
} from '@app/core/types/notification';

const csvOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'text/csv' }),
  responseType: 'text',
};
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
const blobHttpOptions: object = {
  headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' }),
  responseType: 'blob',
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiUrl = environment.baseApiUrl;
  private authUrl = this.apiUrl + 'user/';

  constructor(
    private _http: HttpClient,
    private _unsavedChangesService: UnsavedChangesService,
    private router: Router,
  ) {}

  //-- Auth

  login(credentials: Login): Observable<LoginResponse> {
    try {
      return this.post<LoginResponse>('auth/login', credentials, false);
    } catch (err) {
      throw err;
    }
  }

  passwordForgot(username: string): Observable<any> {
    const body = { username };
    return this.post('auth/password/forgot', body, false);
  }

  passwordReset(resetParams: any): Observable<any> {
    return this.post('auth/password/reset', resetParams, false);
  }

  register(registration: any): Observable<any> {
    return this.post('auth/register', registration, false);
  }

  addPassword(password: string, userId: string): Observable<any> {
    return this.post('auth/add-password', { userId, password }, true);
  }

  changePassword(changeParams: any): Observable<any> {
    return this.post('auth/change-password', changeParams, true);
  }

  getVerificationLink(userId: string): Observable<{ verificationLink: string | undefined }> {
    return this.get<{ verificationLink: string | undefined }>(
      `auth/get-verification-link/${userId}`,
      'json',
      true,
    );
  }

  requestEmailVerification(userId: any): Observable<any> {
    return this.post('auth/request-email-verification', { userId }, true);
  }

  verifyEmail(token: string): Observable<any> {
    return this.post('auth/verify-email', { token }, false);
  }

  getUserWithToken(token: string): Observable<any> {
    return this.post('auth/find-with-token', { token }, false);
  }

  //-- Users
  updateUserActivity(): Observable<any> {
    return this.post('update-user-activity', {}, true);
  }

  /**
   * Gets the requesting user info as identified by jwt token.
   */
  getRequestingUserInfo(): Observable<User> {
    return this.get('info', 'json', true);
  }

  getUser(id: string): Observable<User> {
    return this.get('users/' + id, 'json', true);
  }

  postUser(newUser: UserToUpdate): Observable<any> {
    return this.post('users', newUser, true);
  }

  updateUser(id: any, update: any): Observable<User> {
    return this.put('users/' + id, update, true);
  }

  getUsersForSelect(
    search: string,
    filters: Record<string, any>,
  ): Observable<{ users: User[]; total: number }> {
    const queryParams = objectToQueryParams({
      search,
      ...filters,
    });
    const url = `users-for-select?${queryParams}`;
    return this.get<{ users: User[]; total: number }>(url, 'json', true);
  }

  getUsersForNotification(notificationId: string): Observable<{ users: User[]; total: number }> {
    const queryParams = objectToQueryParams({
      notificationId,
    });
    const url = `users-for-notification?${queryParams}`;
    return this.get<{ users: User[]; total: number }>(url, 'json', true);
  }

  getUsers(
    search: string,
    filters: Record<string, any>,
    pagination?: RequestPagination,
    sorting?: Sorting,
  ): Observable<User[]> {
    const queryParams = objectToQueryParams({
      search,
      ...filters,
      ...pagination,
      ...sorting,
    });

    const url = `users?${queryParams}`;
    return this.get<User[]>(url, 'json', true);
  }

  getUserBadgeInfo(): Observable<{ pendingQuotations: number; pendingOrders?: number }> {
    return this.get<{ pendingQuotations: number }>('badge-info', 'json', true);
  }

  deleteUser(userId: string): Observable<any> {
    return this.delete('users/' + userId, true);
  }

  downloadUserCSV(search: string, filters: Record<string, any>): Observable<any> {
    const queryParams = objectToQueryParams({
      search,
      ...filters,
    });
    const url = `users-csv?${queryParams}`;
    return this.get(url, 'csv', true);
  }

  // -- Clients

  getClients(
    search: string,
    clientType?: ClientType | 'all',
    pagination?: RequestPagination,
    sorting?: Sorting,
  ): Observable<{ clients: ClientListItem[]; total: number }> {
    const queryParams = objectToQueryParams({
      search,
      clientType,
      ...pagination,
      ...sorting,
    });
    const url = `clients?${queryParams}`;
    return this.get<{ clients: ClientListItem[]; total: number }>(url, 'json', true);
  }

  // Get limited client list for client search field
  getClientsForSelect(
    search: string,
    limit?: number,
  ): Observable<{ clients: ClientForSelect[]; total: number }> {
    const queryParams = objectToQueryParams({ search, pageSize: limit });
    const url = `clients-for-select?${queryParams}`;
    return this.get<{ clients: ClientForSelect[]; total: number }>(url, 'json', true);
  }

  // Get limited airline list for airline search field
  getAirlinesForSelect(
    search: string,
    limit?: number,
  ): Observable<{ airlines: AirlineForSelect[]; total: number }> {
    const queryParams = objectToQueryParams({ search, pageSize: limit });
    const url = `airlines-for-select?${queryParams}`;
    return this.get<{ airlines: AirlineForSelect[]; total: number }>(url, 'json', true);
  }

  getClient(clientId: string): Observable<Client> {
    return this.get<Client>(`clients/${clientId}`, 'json', true);
  }

  postClient(newClient: ClientUpdate): Observable<Client> {
    return this.post('clients', newClient, true);
  }

  updateClient(clientId: string, updatedClient: ClientUpdate): Observable<Client> {
    return this.put(`clients/${clientId}`, updatedClient, true);
  }

  deleteClient(id: string): Observable<{ message: string }> {
    return this.delete<{ message: string }>(`clients/${id}`, true);
  }

  //-- Airports

  getAirportWithAirplanes(id: string): Observable<AirportWithAirplanes> {
    return this.get<AirportWithAirplanes>('airports/' + id, 'json', true);
  }

  getAirportsByIds(ids: string[]): Observable<Airport[]> {
    if (!ids || ids.length == 0) return EMPTY;
    const url = `airports-by-ids?${arrayToQueryParams('airportIds', ids)}`;
    return this.get<{ airports: Airport[] }>(url, 'json', true).pipe(map((data) => data.airports));
  }

  getAirportGroupsByIds(ids: string[]): Observable<AirportGroup[]> {
    if (!ids || ids.length == 0) return EMPTY;
    const url = `airport-groups-by-ids?${arrayToQueryParams('ids', ids)}`;
    return this.get<{ airportGroups: AirportGroup[] }>(url, 'json', true).pipe(
      map((data) => data.airportGroups),
    );
  }

  getAirportsBySearchWord(search: string): Observable<Airport[]> {
    const queryParams = objectToQueryParams({
      search,
    });
    const url = `airports-by-search-word?${queryParams}`;
    return this.get<{ airports: Airport[] }>(url, 'json', true).pipe(map((data) => data.airports));
  }

  getAirportGroupsBySearchWord(search: string, airlineId?: string): Observable<AirportGroup[]> {
    const queryParams = objectToQueryParams({
      search,
      airlineId,
    });
    const url = `airport-groups-by-search-word?${queryParams}`;
    return this.get<{ airportGroups: AirportGroup[] }>(url, 'json', true).pipe(
      map((data) => data.airportGroups),
    );
  }

  getAirportsWithAirplaneCount(): Observable<Airport[]> {
    return this.get<{ airports: Airport[] }>('airports', 'json', true).pipe(
      map((data) => data.airports),
    );
  }

  getAirportGroup(id: string): Observable<AirportGroup> {
    return this.get<AirportGroup>('airport-group/' + id, 'json', true);
  }

  createAirportGroup(newAirportGroup: Partial<AirportGroupUpdate>): Observable<AirportGroup> {
    return this.post<AirportGroup>('airport-groups', newAirportGroup, true);
  }

  updateAirportGroup(id: string, update: Partial<AirportGroupUpdate>): Observable<AirportGroup> {
    return this.put<AirportGroup>('airport-group/' + id, update, true);
  }

  deleteAirportGroup(id: string): Observable<{ message: string }> {
    return this.delete<{ message: string }>(`airport-group/${id}`, true);
  }

  getAirportGroupsPaginatedList(
    search: string,
    filters: Record<string, any>,
    pagination?: RequestPagination,
    sorting?: Sorting,
  ): Observable<{ airportGroups: AirportGroup[]; total: number }> {
    const queryParams = objectToQueryParams({
      search,
      ...filters,
      ...pagination,
      ...sorting,
    });
    const url = `airport-groups?${queryParams}`;
    return this.get<{ airportGroups: AirportGroup[]; total: number }>(url, 'json', true);
  }

  //-- Aircrafts

  getAircraftsForSelectComponent(search: string): Observable<any> {
    const queryParams = objectToQueryParams({
      search,
    });
    const url = `aircrafts-for-select?${queryParams}`;
    return this.get(url, 'json', true);
  }

  getAircraft(id: string): Observable<Aircraft> {
    const url = 'aircraft/' + id;
    return this.get<Aircraft>(url, 'json', true);
  }

  createAircraft(newAircraft: Aircraft): Observable<Aircraft> {
    return this.post<Aircraft>('aircrafts', newAircraft, true);
  }

  updateAircraft(id: string, update: Aircraft): Observable<Aircraft> {
    return this.put<Aircraft>('aircraft/' + id, update, true);
  }

  deleteAircraft(id: string): Observable<{ message: string }> {
    const url = 'aircraft/' + id;
    return this.delete<{ message: string }>(url, true);
  }

  getAircraftList(
    search: string,
    filters: Record<string, any>,
    pagination?: RequestPagination,
    sorting?: Sorting,
  ): Observable<{ aircraft: Aircraft[]; total: number }> {
    const queryParams = objectToQueryParams({
      search,
      ...filters,
      ...pagination,
      ...sorting,
    });
    const url = `aircraft-list?${queryParams}`;
    return this.get<{ aircraft: Aircraft[]; total: number }>(url, 'json', true);
  }

  //-- Airplanes

  getAirplane(id: string): Observable<Airplane> {
    return this.get<Airplane>('airplane/' + id, 'json', true);
  }

  getStatsOverview(): Observable<StatsOverview> {
    return this.get<StatsOverview>('stats/overview', 'json', true);
  }

  getSalesStatistics(airlineId?: string, clientId?: string): Observable<any> {
    const queryParams = objectToQueryParams({
      airlineId,
      clientId,
    });
    return this.get(`stats/sales?${queryParams}`, 'json', true);
  }

  getAirlineStatistics(): Observable<any> {
    return this.get(`stats/airlines`, 'json', true);
  }

  getRequestStatistics(
    groupAirports: boolean,
    airlineId?: string,
    clientId?: string,
  ): Observable<any> {
    const queryParams = objectToQueryParams({
      groupAirports,
      airlineId,
      clientId,
    });
    return this.get(`stats/requests?${queryParams}`, 'json', true);
  }

  getClientStatistics(): Observable<any> {
    return this.get('stats/clients', 'json', true);
  }

  getAirplanesForEnquiry(enquiryId: string): Observable<RoutesSearchResult> {
    const url = `enquiries/search-flights?enquiryId=${enquiryId}`;
    return this.get<RoutesSearchResult>(url, 'json', true);
  }

  getPriceForRoute(
    airplaneId: string,
    fromAirportId: string,
    toAirportId: string,
    isTwoWay: boolean,
  ): Observable<{ price: number | null }> {
    const queryParams = objectToQueryParams({
      airplaneId,
      fromAirportId,
      toAirportId,
      isTwoWay,
    });
    return this.get<{ price: number | null }>(
      `route-airplanes/calculate-price?${queryParams}`,
      'json',
      true,
    );
  }

  getAirplaneList(
    search: string,
    filters: AirplaneListFilter,
    pagination?: RequestPagination,
    sorting?: Sorting,
  ): Observable<{ airplanes: AirplaneListItem[]; total: number }> {
    const queryParams = objectToQueryParams({
      search,
      ...filters,
      ...pagination,
      ...sorting,
    });
    const url = `airplanes?${queryParams}`;
    return this.get<{ airplanes: AirplaneListItem[]; total: number }>(url, 'json', true);
  }

  createAirplane(newAirplane: AirplaneToUpdate): Observable<{ id: string }> {
    return this.post<{ id: string }>('airplanes', newAirplane, true);
  }

  updateAirplane(id: string, update: AirplaneToUpdate): Observable<{ message: string }> {
    return this.put<{ message: string }>('airplane/' + id, update, true);
  }

  deleteAirplane(id: string): Observable<{ message: string }> {
    return this.delete<{ message: string }>('airplane/' + id, true);
  }

  //-- Airlines

  getAirlinesPaginatedList(
    search: string,
    filters: Record<string, any>,
    pagination?: RequestPagination,
    sorting?: Sorting,
  ): Observable<{ airlines: AirlineListItem[]; total: number }> {
    const queryParams = objectToQueryParams({
      search,
      ...filters,
      ...pagination,
      ...sorting,
    });
    const url = `airlines?${queryParams}`;
    return this.get<{ airlines: AirlineListItem[]; total: number }>(url, 'json', true);
  }

  getAirlinesList(
    search: string,
    limit?: number,
  ): Observable<{ airlines: LimitedAirline[]; total: number }> {
    const queryParams = objectToQueryParams({ search, pageSize: limit });
    const url = `airlines-list?${queryParams}`;
    return this.get<{ airlines: LimitedAirline[]; total: number }>(url, 'json', true);
  }

  getAirline(id: string): Observable<Airline> {
    return this.get<Airline>(`airlines/${id}`, 'json', true);
  }

  updateAirline(id: string, update: Partial<Airline>): Observable<Airline> {
    return this.put<Airline>('airlines/' + id, update, true);
  }

  createAirline(airline: Partial<Airline>): Observable<Airline> {
    return this.post<Airline>('airlines', airline, true);
  }

  deleteAirlineAttachment(id: string, filename: string) {
    return this.delete<{ defaultAttachments: Attachment[] }>(
      `airline/${id}/delete-attachment/?filename=${encodeURIComponent(filename)}`,
      true,
    );
  }

  deleteAirlinePaxListTemplate(id: string, filename: string): Observable<{ message: string }> {
    return this.delete<{ message: string }>(
      `airline/${id}/delete-pax-list-template/?filename=${encodeURIComponent(filename)}`,
      true,
    );
  }

  downloadAirlinePaxListTemplate(airlineId: string, filename: string): Observable<Blob> {
    return this.get<Blob>(
      `airline/${airlineId}/download-pax-list-template/?filename=${encodeURIComponent(filename)}`,
      'blob',
      true,
    );
  }

  downloadAirlineAttachment(airlineId: string, filename: string): Observable<Blob> {
    return this.get<Blob>(
      `airline/${airlineId}/download-attachment/?filename=${encodeURIComponent(filename)}`,
      'blob',
      true,
    );
  }

  getAirlineDefaultPriceConditions(
    airlineId: string = undefined,
  ): Observable<{ defaultPriceConditions: PriceCondition[] }> {
    return this.get<{ defaultPriceConditions: PriceCondition[] }>(
      `airline/default-price-conditions?${airlineId ? 'id=' + airlineId : ''}`,
      'json',
      true,
    );
  }

  // -- Commissions

  postCommission(commission: Commission): Observable<{ commissionId: string }> {
    return this.post<{ commissionId: string }>('commissions', commission, true);
  }

  updateCommission(
    commissionId: string,
    commission: Commission,
  ): Observable<{ commissionId: string }> {
    return this.put<{ commissionId: string }>(`commission/${commissionId}/`, commission, true);
  }

  deleteCommission(commissionId: string): Observable<{ commissionId: string }> {
    return this.delete<{ commissionId: string }>(`commission/${commissionId}/`, true);
  }

  getCommissions(
    search: string,
    pagination?: RequestPagination,
  ): Observable<{ commissions: CommissionListItem[]; total: number }> {
    const queryParams = objectToQueryParams({
      search,
      ...pagination,
    });
    return this.get<{ commissions: CommissionListItem[]; total: number }>(
      `commissions?${queryParams}`,
      'json',
      true,
    );
  }

  getCommissionById(commissionId: string): Observable<CommissionListItem> {
    return this.get<CommissionListItem>(`commission/${commissionId}`, 'json', true);
  }

  getCommissionByValues(airlineId?: string, clientId?: string) {
    const queryParams = objectToQueryParams({
      airlineId,
      clientId,
    });
    return this.get<CommissionListItem>(`commission?${queryParams}`, 'json', true);
  }

  calculateCommission(
    price: number,
    quotationId: string,
    totalPrice: boolean = false,
  ): Observable<{ commissionValue: number; usedCommission?: QuotationCommission }> {
    const queryParams = objectToQueryParams({ price, quotationId, totalPrice });
    return this.get<{ commissionValue: number; usedCommission?: QuotationCommission }>(
      `commission/calculate-commission?${queryParams}`,
      'json',
      true,
    );
  }

  // -- Enquiries

  postEnquiry(enquiry: EnquiryPostData): Observable<{ enquiryId: string }> {
    return this.post<{ enquiryId: string }>('newEnquiry', enquiry, true);
  }

  getEnquiries(
    search: string,
    pagination?: RequestPagination,
  ): Observable<{ enquiries: EnquiryListItem[]; total: number }> {
    const queryParams = objectToQueryParams({
      search,
      ...pagination,
    });
    return this.get<{ enquiries: EnquiryListItem[]; total: number }>(
      `enquiries?${queryParams}`,
      'json',
      true,
    );
  }

  getEnquiry(enquiryId: string): Observable<Enquiry> {
    return this.get<Enquiry>(`enquiries/${enquiryId}`, 'json', true);
  }

  sendManualContactRequest(enquiryId: string): Observable<void> {
    return this.post<void>(`enquiries/${enquiryId}/manual-contact-request`, undefined, true);
  }

  /* Quotations */

  adminCreateQuotation(
    enquiryId: string,
    airlineId: string,
    airplaneId: string | undefined,
    clientId: string | undefined,
  ): Observable<{ quotationId: string }> {
    return this.post<{ quotationId: string }>(
      'newQuotation',
      {
        enquiryId,
        airlineId,
        airplaneId,
        clientId,
      },
      true,
    );
  }

  airlineCreateOffer(params: {
    clientEmail: string;
    clientName: string;
    freeText?: string;
    airlineId?: string;
  }): Observable<{ _id: string; referenceNumber: string }> {
    return this.post<{ _id: string; referenceNumber: string }>(
      'quotations/airline-create-offer',
      {
        ...params,
      },
      true,
    );
  }

  llmGetEnquiry(params: { freeText?: string }): Observable<{ enquiryId: string }> {
    return this.post<{ enquiryId: string }>(
      'enquiries/ai-get-enquiry',
      {
        ...params,
      },
      true,
    );
  }

  getQuotationsForList(
    filter: {
      search?: string;
      status: QuotationStatus[];
      referenceNumber?: number;
      quotationId?: string;
      enquiryId?: string;
      onlySubscribed?: boolean;
      caseReference?: string;
    },
    pagination?: RequestPagination,
    sorting?: Sorting,
  ): Observable<QuotationResponse> {
    const queryParams = objectToQueryParams({
      ...filter,
      ...pagination,
      ...sorting,
    });
    const url = `quotations?${queryParams}`;
    return this.get<QuotationResponse>(url, 'json', true);
  }

  getQuotation(quotationId: string): Observable<Quotation> {
    return this.get<Quotation>(`quotations/${quotationId}`, 'json', true);
  }

  getQuotationHistory(quotationId: string): Observable<QuotationHistory> {
    return this.get<QuotationHistory>(`quotation/${quotationId}/get-previous-values`, 'json', true);
  }

  getQuotationForExport(quotationId: string): Observable<string> {
    return this.get<string>(`quotation/${quotationId}/get-quotation-for-export`, 'json', true);
  }

  quotationForward(
    enquiryId: string,
    airlineId: string,
    clientId: string,
    quotationNotes: string | undefined,
  ): Observable<{ quotationId: string }> {
    return this.post<{ quotationId: string }>(
      'forward-quotation',
      {
        enquiryId,
        airlineId,
        clientId,
        quotationNotes: quotationNotes ?? '',
      },
      true,
    );
  }

  quotationSendOffer(
    quotationId: string,
    updateData: QuotationToUpdate,
  ): Observable<{ message: string }> {
    return this.put<{ message: string }>(`quotation/${quotationId}/send-offer`, updateData, true);
  }

  quotationAirlineUpdateOffer(
    quotationId: string,
    updateData: QuotationToUpdate,
  ): Observable<{ message: string }> {
    return this.put<{ message: string }>(
      `quotation/${quotationId}/airline-update-offer`,
      updateData,
      true,
    );
  }

  quotationAirlineSendContract(
    quotationId: string,
    updateData: QuotationToUpdate,
  ): Observable<{ message: string }> {
    return this.put<{ message: string }>(
      `quotation/${quotationId}/airline-send-contract`,
      updateData,
      true,
    );
  }

  quotationAdminUpdateOffer(
    quotationId: string,
    updateData: QuotationToUpdate,
  ): Observable<{ message: string }> {
    return this.put<{ message: string }>(
      `quotation/${quotationId}/admin-update-offer`,
      updateData,
      true,
    );
  }

  quotationRequestForAmendment(
    quotationId: string,
    declineReason: string,
  ): Observable<{ message: string }> {
    return this.put<{ message: string }>(
      `quotation/${quotationId}/request-for-amendment`,
      {
        declineReason,
      },
      true,
    );
  }

  quotationDecline(
    quotationId: string,
    updateData: QuotationToUpdate,
    declineReason: string,
    behalfOf?: 'client' | 'airline',
  ): Observable<{ message: string }> {
    return this.put<{ message: string }>(
      `quotation/${quotationId}/decline`,
      {
        declineReason,
        behalfOf,
        ...updateData,
      },
      true,
    );
  }

  quotationCancel(
    quotationId: string,
    declineReason: string,
    behalfOf?: 'client' | 'airline',
  ): Observable<{ message: string }> {
    return this.put<{ message: string }>(
      `quotation/${quotationId}/cancel`,
      {
        declineReason,
        behalfOf,
      },
      true,
    );
  }

  quotationClientRequestContract(quotationId: string): Observable<{ message: string }> {
    return this.put<{ message: string }>(
      `quotation/${quotationId}/client-request-contract`,
      undefined,
      true,
    );
  }

  quotationAirlineFinalConfirm(
    quotationId: string,
    updateData: QuotationToUpdate,
  ): Observable<{ message: string }> {
    return this.put<{ message: string }>(
      `quotation/${quotationId}/airline-final-confirm`,
      updateData,
      true,
    );
  }

  quotationClientConfirmOffer(quotationId: string): Observable<{ message: string }> {
    return this.put<{ message: string }>(
      `quotation/${quotationId}/client-confirm-offer`,
      undefined,
      true,
    );
  }

  updateQuotationAdminNotes(
    quotationId: string,
    adminNotes: string,
  ): Observable<{ message: string }> {
    return this.put<{ message: string }>(
      `quotation/${quotationId}/admin-notes`,
      {
        adminNotes,
      },
      true,
    );
  }

  updateQuotationSubscription(
    quotationId: string,
    subscribe: boolean,
  ): Observable<{ message: string }> {
    return this.put<{ message: string }>(
      `quotation/${quotationId}/update-subscription`,
      {
        subscribe,
      },
      true,
    );
  }

  downloadAttachment(quotationId: string, filename: string): Observable<Blob> {
    return this.get<Blob>(
      `quotation/download-attachment/${quotationId}?filename=${encodeURIComponent(filename)}`,
      'blob',
      true,
    );
  }

  deleteAttachment(
    quotationId: string,
    filename: string,
  ): Observable<{ attachments: Attachment[] }> {
    return this.delete<{ attachments: Attachment[] }>(
      `quotation/${quotationId}/delete-attachment/${encodeURIComponent(filename)}`,
      true,
    );
  }

  downloadContractTerms(quotationId: string, filename: string): Observable<Blob> {
    return this.get<Blob>(
      `quotation/download-contract-terms/${quotationId}?filename=${encodeURIComponent(filename)}`,
      'blob',
      true,
    );
  }

  deleteContractTerms(
    quotationId: string,
    filename: string,
  ): Observable<{ contractTerms: Attachment[] }> {
    return this.delete<{ contractTerms: Attachment[] }>(
      `quotation/${quotationId}/delete-contract-terms/${encodeURIComponent(filename)}`,
      true,
    );
  }

  sendOfferRequestReminders(id: string): Observable<{ message: string }> {
    return this.post(`quotations/${id}/offer-request-reminder`, null, true);
  }

  sendOfferReminders(id: string): Observable<{ message: string }> {
    return this.post(`quotations/${id}/offer-reminder`, null, true);
  }

  postponeQuotation(
    quotationId: string,
    updateData: QuotationToUpdate & PostponedData,
  ): Observable<{ message: string }> {
    return this.post<{ message: string }>(
      `quotations/${quotationId}/postpone-offer`,
      {
        ...updateData,
      },
      true,
    );
  }

  updateQuotationInternalNotes(
    id: string,
    update: { internalNotes: string; type: 'client' | 'airline' },
  ): Observable<{ message: string }> {
    return this.put<{ message: string }>(`quotation/${id}/update-internal-notes`, update, true);
  }

  updateClientEmergencyContactInfo(
    id: string,
    update: EmergencyContact,
  ): Observable<{ message: string }> {
    return this.put<{ message: string }>(
      `quotation/${id}/update-client-emergency-contact`,
      update,
      true,
    );
  }

  updateAirlineEmergencyContactInfo(
    id: string,
    update: EmergencyContact,
  ): Observable<{ message: string }> {
    return this.put<{ message: string }>(
      `quotation/${id}/update-airline-emergency-contact`,
      update,
      true,
    );
  }

  //-- Orders
  updateOrderCrewNotes(id: string, crewNotes: string): Observable<{ message: string }> {
    return this.put(`orders/${id}/update-crew-notes/`, { crewNotes }, true);
  }

  updateOrderGroundServiceNotes(
    id: string,
    groundServiceNotes: string,
  ): Observable<{ message: string }> {
    return this.put(`orders/${id}/update-ground-service-notes/`, { groundServiceNotes }, true);
  }

  updateOrderCateringNotes(id: string, cateringNotes: string): Observable<{ message: string }> {
    return this.put(`orders/${id}/update-catering-notes/`, { cateringNotes }, true);
  }

  downloadPaxList(orderId: string, filename: string): Observable<Blob> {
    return this.get(
      `orders/download-pax-list/${orderId}?filename=${encodeURIComponent(filename)}`,
      'blob',
      true,
    );
  }

  deletePaxList(orderId: string, filename: string): Observable<{ paxList: Attachment[] }> {
    return this.delete<{ paxList: Attachment[] }>(
      `orders/${orderId}/delete-pax-list/${encodeURIComponent(filename)}`,
      true,
    );
  }

  downloadInvoiceFile(orderId: string, filename: string): Observable<Blob> {
    return this.get(
      `orders/download-invoice/${orderId}?filename=${encodeURIComponent(filename)}`,
      'blob',
      true,
    );
  }

  deleteInvoice(orderId: string, fileId: string): Observable<{ invoiceAttachments: Attachment[] }> {
    return this.delete(`orders/${orderId}/delete-invoice/${fileId}`, true);
  }

  updateOrderStatus(
    orderId: string,
    action: OrderAction | null = null,
  ): Observable<{
    status: QuotationStatus;
    paidAt?: string;
    depositPaidAt?: string;
  }> {
    return this.put(`orders/${orderId}/update-status/`, { action }, true);
  }

  // Service item refers to a product that airline is selling to the client
  updateSpecialRequest(
    orderId: string,
    specialRequests: SpecialRequest[],
  ): Observable<SpecialRequest[]> {
    return this.put<{ specialRequests: SpecialRequest[] }>(
      `orders/${orderId}/update-special-request/`,
      { specialRequests },
      true,
    ).pipe(map((data) => data.specialRequests));
  }

  // --Airplanes
  getBusyTimesByAirplane(
    filters: Record<string, any>,
    pagination?: RequestPagination,
  ): Observable<any> {
    const queryParams = objectToQueryParams({
      ...filters,
      ...pagination,
    });
    const url = `orders/getBusyTimesByAirplane?${queryParams}`;
    return this.get(url, 'json', true);
  }

  /* TODO: unused & unworking availability stuff. There is not such endpoint as used below.
  getBusyTimesAirplanesByAirline(
    filters: Record<string, any>,
    pagination?: RequestPagination,
  ): Observable<any> {
    const queryParams = objectToQueryParams({
      ...filters,
      ...pagination,
    });
    const url = `orders/getBusyTimesAirplanesByAirline?${queryParams}`;
    return this.get(url, 'json', true);
  }
  */

  //-- messages

  getQuotationChatMessages(
    quotationId: string,
    updatedAt?: string,
  ): Observable<{ messages: ChatMessage[]; updatedAtMatches: boolean; updatedAt: string }> {
    const queryParams = objectToQueryParams({
      updatedAt,
    });

    return this.get<{ messages: ChatMessage[]; updatedAtMatches: boolean; updatedAt: string }>(
      `chat-messages/${quotationId}?${queryParams}`,
      'json',
      true,
    );
  }

  sendChatMessage(
    message: { content: string; attachment?: Attachment },
    quotationId: string,
  ): Observable<{ messageId: string }> {
    return this.post<{ messageId: string }>(`chat-messages/${quotationId}`, message, true);
  }

  saveChatMessageContent(messageId: string, content: string): Observable<any> {
    return this.put<{ messageId: string }>(`message/${messageId}`, { content: content }, true);
  }

  sendLLMMessage(messages: LLMMessage[]): Observable<LLMMessage> {
    return this.put<LLMMessage>('llm', { messages: messages }, true);
  }

  deleteMessage(messageId: string): Observable<any> {
    return this.delete('message/' + messageId, true);
  }

  deleteMessageAttachments(messageId: string): Observable<any> {
    return this.delete('message/' + messageId + '/delete-attachments', true);
  }

  downloadMessageFile(messageId: string, fileId: string): Observable<any> {
    return this.get('messages/' + messageId + '/download/' + fileId, 'blob', true);
  }

  createNotification(
    notification: Partial<NotificationUpdate>,
  ): Observable<{ notificationId: string }> {
    return this.post<{ notificationId: string }>('notifications', notification, true);
  }

  updateNotification(
    notificationId: string,
    notification: Partial<NotificationUpdate>,
  ): Observable<{ notificationId: string }> {
    return this.put<{ notificationId: string }>(
      'notification/' + notificationId,
      notification,
      true,
    );
  }

  deleteNotification(notificationId: string): Observable<any> {
    return this.delete('notification/' + notificationId, true);
  }

  markNotificationsAsSeen(notificationIds: string[]): Observable<any> {
    return this.put<{ seenAt: string }>(`mark-notifications-as-seen`, { notificationIds }, true);
  }

  getNotificationsForList(
    search?: string,
    sourceFilter?: NotificationSource | 'all',
    pagination?: RequestPagination,
    sorting?: Sorting,
  ): Observable<{ notifications: NotificationListItem[]; total: number }> {
    const queryParams = objectToQueryParams({
      search,
      sourceFilter,
      ...pagination,
      ...sorting,
    });
    return this.get<{ notifications: NotificationListItem[]; total: number }>(
      `notifications?${queryParams}`,
      'json',
      true,
    );
  }

  getNotificationById(notificationId: string): Observable<NotificationProperties> {
    return this.get('notification/' + notificationId, 'json', true);
  }

  getNotificationsForUser(
    publishedSince?: string | Date,
    notificationType: NotificationType = NotificationType.ALL,
  ): Observable<{ total: number; notifications: NotificationForUser[] }> {
    const params = { publishedSince, notificationType };
    if (!publishedSince) delete params.publishedSince;
    const queryParams = objectToQueryParams({
      ...params,
    });
    return this.get<{ total: number; notifications: NotificationForUser[] }>(
      `get-notifications-for-user?${queryParams}`,
      'json',
      true,
    );
  }

  getRecentMessageInformation(): Observable<{
    count: number;
    messages: RecentMessageInformation[];
  }> {
    return this.get('recent-messages/', 'json', true);
  }

  //-- Checks

  airlineHasUsers(id: string): Observable<any> {
    return this.get('check/registered/airline/' + id, 'json', false);
  }

  airlineExists(id: string): Observable<any> {
    return this.get('check/exists/airline/' + id, 'json', false);
  }

  airlineExistsForExternalBooking(id: string): Observable<any> {
    return this.get('check/exists/airline/' + id + '?forExternalBooking=true', 'json', false);
  }

  clientHasUsers(id: string): Observable<any> {
    return this.get('check/registered/client/' + id, 'json', false);
  }

  getVersion(): Observable<any> {
    return this.get('check/version', 'json', false);
  }

  //-- Action log

  getActionLogList(
    filters: ActionLogFilter,
    pagination?: RequestPagination,
    sorting?: Sorting,
  ): Observable<{ total: number; actionLog: ActionLog[] }> {
    const queryParams = objectToQueryParams({
      ...filters,
      ...pagination,
      ...sorting,
    });
    const url = `action-log?${queryParams}`;
    return this.get(url, 'json', true);
  }

  //-- external

  postExternalBooking(booking: ExternalBooking): Observable<{ quotationId: string } | Error> {
    return this.post<{ quotationId: string }>('external/book-flight', booking, false);
  }

  checkFlight(body: {
    airlineId: string;
    routeType: string;
    routes: QuotationRouteToUpdate[];
    flexibleDates: boolean;
    otherRemarks?: string;
    groupType?: string;
  }): Observable<{ message: string } | Error> {
    return this.post<{ message: string }>(`external/check-flight`, body, false);
  }

  getExternalAirline(airlineId: string): Observable<ExternalAirline> {
    return this.get<ExternalAirline>('external/get-airline/' + airlineId, 'json', false);
  }

  private get<T>(
    url: string,
    type: 'json' | 'csv' | 'blob',
    useAuthenticatedUrl: boolean,
  ): Observable<T> {
    const baseUrl = useAuthenticatedUrl ? this.authUrl : this.apiUrl;
    const fullUrl = baseUrl + url;
    let options = {};
    if (type === 'json') options = httpOptions;
    if (type === 'blob') options = blobHttpOptions;
    if (type === 'csv') options = csvOptions;
    return this._http.get<T>(fullUrl, options).pipe(catchError(this.handleError.bind(this)));
  }

  private post<T>(url: string, body: any | null, useAuthenticatedUrl: boolean): Observable<T> {
    const baseUrl = useAuthenticatedUrl ? this.authUrl : this.apiUrl;
    const fullUrl = baseUrl + url;
    return this._http.post<T>(fullUrl, body).pipe(catchError(this.handleError.bind(this)));
  }

  private put<T>(url: string, body: any | null, useAuthenticatedUrl: boolean): Observable<T> {
    const baseUrl = useAuthenticatedUrl ? this.authUrl : this.apiUrl;
    const fullUrl = baseUrl + url;
    return this._http.put<T>(fullUrl, body).pipe(catchError(this.handleError.bind(this)));
  }

  private delete<T>(url: string, useAuthenticatedUrl: boolean): Observable<T> {
    const baseUrl = useAuthenticatedUrl ? this.authUrl : this.apiUrl;
    const fullUrl = baseUrl + url;
    return this._http.delete<T>(fullUrl, httpOptions).pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(error: { message: string; code: string; translationKey: string }) {
    // The backend returned an unsuccessful response code.
    if (error.code === 'Unauthorized') {
      console.error('Backend returned "UNAUTHORIZED", logging user out');
      this._unsavedChangesService.hasUnsavedChanges.next(false);
      setTimeout(() => {
        this.router.navigate(['/auth/logout']);
      }, 500);
    }
    return throwError(() => error);
  }

  getFileUploadAPIUrl = (fileType: AttachmentType) => {
    switch (fileType) {
      case AttachmentType.ATTACHMENT:
      case AttachmentType.SEAT_MAP:
      case AttachmentType.ADMIN_FILE_TO_CLIENT:
      case AttachmentType.ADMIN_FILE_TO_AIRLINE:
        return 'user/quotation/upload-attachment/';
      case AttachmentType.CONTRACT:
        return 'user/quotation/upload-contract-terms/';
      case AttachmentType.INVOICE:
        return 'user/orders/upload-invoices/';
      case AttachmentType.PAX_LIST:
        return 'user/orders/upload-pax-list/';
      case AttachmentType.PAX_LIST_TEMPLATE:
        return 'user/airline/upload-pax-list/';
      default:
        throw new Error('Invalid attachment upload type: ' + fileType);
    }
  };
}
