import { Attachment } from '@app/core/types/attachment';

export enum NotificationType {
  ALL = 'all',
  POPUP = 'popup',
  NOTIFICATION = 'notification',
}

export enum NotificationSource {
  ACTION = 'action',
  CHAT = 'chat',
  ANNOUNCEMENT = 'announcement',
  SYSTEM = 'system',
}

export type NotificationUser = {
  _id: string;
  seenAt?: Date;
  deleted?: Date;
};

export type NotificationRelatedTo = {
  enquiry?: string;
  quotation?: string;
  order?: string;
  user?: string;
  airplane?: string;
  airline?: string;
  client?: string;
  airportGroup?: string;
};

export const DEFAULT_NOTIFICATION_USER_FILTER = {
  search: '',
  status: 'all',
  rootFilter: true,
  airlineFilter: true,
  travelAgencyFilter: true,
  brokerFilter: true,
  consumerFilter: true,
  otherCompanyFilter: true,
};

export type NotificationUserFilter = {
  search?: string;
  status?: string;
  rootFilter?: boolean;
  airlineFilter?: boolean;
  travelAgencyFilter?: boolean;
  brokerFilter?: boolean;
  consumerFilter?: boolean;
  otherCompanyFilter?: boolean;
};

export type NotificationForUser = Omit<NotificationProperties, 'users'> & {
  seenAt: Date;
  showContentRows: string[];
};

export type NotificationProperties = {
  _id: string;
  source: NotificationSource;
  title: string;
  content: string;
  popup?: boolean;
  menuNotification?: boolean;
  publishTime?: string;
  attachments: Attachment[];
  relatedTo: NotificationRelatedTo;
  users: NotificationUser[];
  userFilters: NotificationUserFilter;
  createdByUser: {
    _id: string;
    firstName: string;
    lastName: string;
  };
  deleted?: string;
};

export type NotificationUpdate = {
  source: string;
  title: string;
  content: string;
  popup?: boolean;
  menuNotification?: boolean;
  publishTime?: string;
  users: string[];
  userFilters: NotificationUserFilter;
};

export type NotificationListResponse = {
  notifications: NotificationListItem[];
  total: number;
};
export type NotificationListItem = {
  _id?: string;
  source?: string;
  title?: string;
  content?: string;
  popup?: boolean;
  menuNotification?: boolean;
  publishTime?: Date | string;
  attachmentCount?: number;
  relatedTo?: {
    enquiry?: string;
    quotation?: string;
    order?: string;
    user?: string;
    airplane?: string;
    airportGroup?: string;
    airline?: string;
    client?: string;
  };
  userCount?: number;
  createdByUser: {
    _id: string;
    firstName: string;
    lastName: string;
  };
  deleted?: Date | string;
};
