<div fxLayout="column" fusePerfectScrollbar>
  <mat-toolbar class="mat-toolbar mat-accent">
    <h2 mat-dialog-title data-test="dialog-title">{{ notification.title }}</h2>
  </mat-toolbar>
  <mat-dialog-content>
    <p>
      <span
        style="white-space: pre-wrap"
        innerHtml="{{ notification.content }}"
        data-test="dialog-content"></span>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-raised-button
      color="accent"
      (click)="onClose()"
      fxAlignLayout="center"
      data-test="notification-ok-btn">
      {{ 'OK' }}
    </button>
  </mat-dialog-actions>
</div>
